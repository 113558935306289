import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'
import validator from 'validator'
import { connectNode } from '../../../utils/global'
import { escapeHtml, validateEmail } from '../../../utils/textFunctions'
import '../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import { ans_yesno } from '../variables/patient.js'

import InputAdornment from '@material-ui/core/InputAdornment'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import EventIcon from '@material-ui/icons/Event'
//import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PhoneIcon from '@material-ui/icons/Phone'
import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

class AddPatientWaitList extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          uid: 0,
          created_uid: 0,//this.props.authReducer.uid,
          name: '',
          email: '',
          phone: '',
          age: 0,
          ma_resident: 0,
          message: '',
      }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      uid: this.props.uid,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(!validator.isEmail(this.state.email)) {
      this.setState({
        message: 'Please fill email in the right format.'
      })
      window.scrollTo(0, 0)
    } else {
      if(this.state.email.trim() !== '' && this.state.name.trim() !== ''
        && this.state.phone.trim() !== '' && this.state.age != 0
        && this.state.ma_resident != 0) {
          axios({
            method: 'post',
            url: connectNode + 'admin/register_patient/add_new_patient_waitlist',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              uid: 0, //this.props.authReducer.uid,
              user: this.state,
            }
          })
          .then((result) => {
            var message = ''
            if(result['data'] > 0) {
              this.props.onLoadLocation('patient_list', 'Added a new patient to the wait list.')
              this.props.history.push('/admin/wait_list')
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
      } else {
        var message = ''
        if(this.state.name === '') {
          message = 'Please fill patient name.'
        } else if(this.state.phone === '') {
          message = 'Please fill patient phone number.'
        } else if(this.state.age === 0) {
          message = 'Please fill patient age.'
        }
        this.setState({
          message: message,
        })
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    return (
      <div className="admin_add_patient box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Patient Wait List
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <form id="add_new_patient" onSubmit={this.handleSubmit}>
              <div className="row">
                { this.state.message !== '' &&
                  <div className="small-12 columns warning_msg6">
                    <div className="float_left1"><WarningIcon /></div>
                    <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>
                }
              </div>
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Patient Name</div>
                      <div className="small-12 medium-8 columns float_left">
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="text" defaultValue={this.state.name}
                        InputProps={{ inputProps: { name: "name" }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Patient email address</div>
                      <div className="small-12 medium-8 columns float_left">
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="email" defaultValue={this.state.email}
                        InputProps={{ inputProps: { name: "email" }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Patient phone number</div>
                      <div className="small-12 medium-8 columns float_left">
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="text" defaultValue={this.state.phone}
                        InputProps={{ inputProps: { name: "phone" }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Patient age</div>
                      <div className="small-12 medium-8 columns float_left">
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="text" defaultValue={this.state.age}
                        InputProps={{ inputProps: { name: "age" }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Patient MA resident</div>
                      <div className="small-12 medium-8 columns float_left top_space1">
                      {ans_yesno.map((each, index) =>
                        <div className="small-6 medium-3 columns float_left" key={'gte'+each.label}>
                          <input type="radio" name="ma_resident" value={each.value}
                          id={'q1'+index}
                          onChange={(e) => this.handleChange2(e, 'ma_resident')}/>
                          <label for={'q1'+index}>{each.label}</label>
                        </div>)}
                      </div>
                    </div>
                  </div>

                  <div className="row top_space2">
                    <div className="small-12 columns float_right">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddPatientWaitList)
