import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/team1.jpg'

class Intro1 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction2')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="small-12 columns title_3 top_space1_1">
            Introducing FITT-BD:
            </div>
            <div className="small-12 columns title_4 bottom_space3">Focused Integrative Team-based Treatment for Bipolar Disorder</div>
            <div className="submit_button3">
              <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro1)
