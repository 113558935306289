import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/list1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
//import Button from '@material-ui/core/Button'

import PatientHead3 from "../../patient/patient_head3"
import SummaryNotes from "./summary_notes"
import TabPatient from "./tab_patient"
import TabAssessments from "./tab_assessments"
import TabInformation from "./tab_information"
import TabMedication from "./tab_medication"
import TabContact from "./tab_contact"
import TabAppointment from "./tab_appointment"
import TabContactLog from "./tab_contact_log"
import TabTreatment from "./tab_treatment"
import TabCourses from "./tab_courses"
//import LogsContactList from "./logs_contact_list"

class PatientChart extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          main_tab: '1',
          tab_status: '1',
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          date: '',
          time: '',
          duration: 0,
          patient_status: 0,
          loan_tablet: 0,
          determine_bipolar: 0,
          reg_status: 0,
      }
  }

  componentDidMount() {
    this.getPatientStatus()
  }

  getPatientStatus = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_patient_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      //console.log(result)
        this.setState({
          patient_status: result['data'].status,
          loan_tablet: result['data'].loan_tablet,
          determine_bipolar: result['data'].determine_bipolar,
          main_tab: this.props.match.params.maintab,
          reg_status: result['data'].reg_status,
        })
      }
    )
  }

  handleMainTabChange = (event, newValue) => {
    this.setState({
      main_tab: newValue,
    })
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_status: newValue,
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/add_new_patient',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: this.state
    })
    .then((result) => {
        //console.log('Callback: '+result['data'])
        if(result['data'] > 0) {
          //this.props.onLoadPages(response)
          this.props.onLoadLocation('patient_list', 'Adding new patient completed!')
          this.props.history.push('/admin/patient_list')
          /*this.setState({
            message: 'Adding new patient completed!'
          })*/
        } else if(result['data'] === -1) {
          this.setState({
            message: 'This email already exist.'
          })
        } else if(result['data'] === -2) {
          this.setState({
            message: 'Adding new patient completed but system couldn\'t send email reminder!'
          })
        } else {
          this.setState({
            message: 'Something went wrong. Please try again.'
          })
        }
      }
    )
  }

  render() {
    //console.log(this.state)
    return (
      <div className="admin_patient_detail box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Patient Chart
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg7">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="box_border5">
                <PatientHead3 />
                {this.state.reg_status == 6 && <SummaryNotes />}
                <div className="row">
                  <div className="small-12 columns tab_data2 main_tab">
                    <TabContext value={this.state.main_tab}>
                      <AppBar position="static">
                      {((this.state.patient_status === 0
                        || this.state.patient_status === 1 || this.state.patient_status === 2
                        || this.state.patient_status === 3 || this.state.patient_status === 4
                        || this.state.patient_status === 6 || this.state.patient_status === 11
                        || this.state.patient_status === 10 || this.state.patient_status === 12
                        || this.state.patient_status === 13 || this.state.patient_status === 14
                        || this.state.patient_status === 16)
                        && ((this.state.loan_tablet == 0 && this.state.determine_bipolar == 0)
                        || (this.state.loan_tablet == null && this.state.determine_bipolar == null))) &&
                        <TabList onChange={this.handleMainTabChange} aria-label="simple tabs example">
                          <Tab label="Appointments" value="1" />
                          <Tab label="Contact Logs" value="2" />
                          <Tab label="Treatment" value="3" />
                          <Tab label="Medication" value="4" />
                          <Tab label="Assessments" value="5" />
                          <Tab label="Information" value="6" />
                          <Tab label="Resources" value="8" />
                        </TabList>}
                        {(this.state.patient_status == 7 || this.state.patient_status == 8
                          || this.state.patient_status == 71 || this.state.patient_status == 81
                          || ((this.state.patient_status == 10 || this.state.patient_status == 11) && (this.state.loan_tablet > 0 || this.state.determine_bipolar > 0))
                          || this.state.loan_tablet > 0 || this.state.determine_bipolar > 0) &&
                          <TabList onChange={this.handleMainTabChange} aria-label="simple tabs example">
                            <Tab label="Appointments" value="1" />
                            <Tab label="Contact Logs" value="2" />
                            <Tab label="Treatment" value="3" />
                            <Tab label="Medication" value="4" />
                            <Tab label="Assessments" value="5" />
                            <Tab label="Information" value="6" />
                            <Tab label="Tablet/Screening" value="7" />
                            <Tab label="Resources" value="8" />
                          </TabList>}
                      </AppBar>
                      <TabPanel value="1" index={1} ><TabAppointment /></TabPanel>
                      <TabPanel value="2" index={2} ><TabContactLog /></TabPanel>
                      <TabPanel value="3" index={3} ><TabTreatment /></TabPanel>
                      <TabPanel value="4" index={4} ><TabMedication /></TabPanel>
                      <TabPanel value="5" index={5} ><TabAssessments /></TabPanel>
                      <TabPanel value="6" index={6} ><TabInformation /></TabPanel>
                      {(this.state.patient_status == 7 || this.state.patient_status == 8 || this.state.loan_tablet > 0 || this.state.determine_bipolar > 0) &&
                        <TabPanel value="7" index={7} ><TabContact patient_status={this.state.patient_status} loan_tablet={this.state.loan_tablet} determine_bipolar={this.state.determine_bipolar} /></TabPanel>
                      }
                      <TabPanel value="8" index={8} ><TabCourses /></TabPanel>
                    </TabContext>
                  </div>
                </div>
                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                    <Link to="/admin/patient_list" className="go_back">Go Back</Link>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientDetail STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientChart)
