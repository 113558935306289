import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/fittbd/online_platform.png'

class Intro7 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction8')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction6')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
          <div className="title_31 margin-bottom1">
          Online Platform
          </div>
          <div className="row bottom_space3">
            <div className="small-12 columns">
              <div className="img_center">
                <img src={Image} alt="group" className="img2" />
              </div>
            </div>
            <div className="small-12 columns content11 margin-top1">
              <p>A major component of what makes FITT-BD work is our online platform.</p>
              <p>As a FITT-BD patient, you'll log information such as mood, sleep, and exercise on a daily or weekly basis, and complete weekly and monthly questionnaires.</p>
              <p>This helps you and your team keep track of how you're doing and adjust your treatment plan if necessary.</p>
            </div>
          </div>
          <div className="row">
            <div className="small-4 columns back_button">
              <Button variant="contained" color="primary" disableElevation
               onClick={this.handleBack} type="button" >
                Previous
              </Button>
            </div>
            <div className="small-4 columns next_button">
              <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro7)
