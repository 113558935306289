import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import '../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import validator from 'validator'
import { ans_yesno, waitlist_status } from '../variables/patient.js'

class EditPatientWaitList extends Component {
  constructor() {
      super();
      this.state = {
          msg_status: false,
          message: '',
          getData: [],
          name: '',
          email: '',
          phone: '',
          age: 0,
          ma_resident: 0,
          status: 0,
          getStatus: 0,
      }
  }

  componentDidMount() {
    this.getPatientStatusList()
  }

  getPatientStatusList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/get_patient_waitlist',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.match.params.pid }
    })
    .then((result) => {
      this.setState({
        fullname: result['data'].fullname,
        email: result['data'].email,
        phone: result['data'].phone,
        age: result['data'].age,
        ma_resident: result['data'].ma_resident,
        status: result['data'].status,
        getStatus: result['data'].status,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      getData: {[name]: e.target.value}
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/edit_patient_waitlist_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: pid,
        user: this.state,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'] == pid) {
        this.props.onLoadLocation('/admin/wait_list', 'Updated status successfully!')
        this.props.history.push('/admin/wait_list')
      }
    })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="admin_make_appointment box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Patient Wait List
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className={(!this.state.msg_status && "row warning_msg6") || this.state.msg_status && "row warning_msg7"}>
              <div className="small-12 columns ">
                <div className="float_left1">
                {!this.state.msg_status && <WarningIcon />}
                {this.state.msg_status && <CheckCircleOutlineIcon />}
                </div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="row">
            {this.state.getStatus != 0 &&
              <div className="small-12 columns box_inner2 top_space1">
                <div className="row top_space1">
                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_txt5">Patient Name</div>
                    <div className="small-12 medium-8 columns float_left">
                      {this.state.fullname}
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_txt5">Patient email address</div>
                    <div className="small-12 medium-8 columns float_left">
                      {this.state.email}
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_txt5">Patient phone number</div>
                    <div className="small-12 medium-8 columns float_left">
                      {this.state.phone}
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_txt5">Patient age</div>
                    <div className="small-12 medium-8 columns float_left">
                      {this.state.age}
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_txt5">Patient MA resident</div>
                    <div className="small-12 medium-8 columns float_left">
                    {this.findLabelByValue(ans_yesno, this.state.ma_resident)}
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_txt5">Patient wait list status</div>
                    <div className="small-12 medium-8 columns float_left">
                    {waitlist_status.map(each =>
                      <div className="small-12 medium-3 columns float_left" key={"w"+each.value}>
                        <input type="radio" name="status" value={each.value}
                        checked={this.state.status == each.value && 'checked'}
                        disabled={this.state.getStatus > 1 && 'disabled'}
                        onChange={(e) => this.handleChange(e)}/>
                        <span>{each.label}</span>
                      </div>)}
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_txt5">&nbsp;</div>
                    <div className="small-12 medium-8 columns float_left">
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Update Status
                    </Button>
                    </div>
                  </div>
                </div>

              </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditPatientWaitList)
