import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
//import '../../../styles/assets/css/pages/dashboard.css'
import '../../../styles/assets/css/pages/graph.css'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { NavLink } from "react-router-dom"
import { FiSettings } from 'react-icons/fi'
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis, VictoryScatter, VictoryGroup } from 'victory'
import { db_type, db_data_show, db_scale_day, db_scale_week } from '../variables/dashboard.js'
import { ch_daily_tracker_sleep, ch_weekly_tracker_sleep,
  ch_daily_tracker_medication, ch_weekly_tracker_medication,
  ch_daily_tracker_exercise, ch_weekly_tracker_exercise,
  ch_daily_weekly_mood_dep2, ch_daily_weekly_mood_dep3, ch_daily_weekly_mood_manic2,
  ch_daily_weekly_anxiety2 } from '../../admin/patient/variables/assessments_scale.js'

class Graph extends Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      graph_config_status: 1, // 1:hide, 2:show
      graph_type: 1, // 1:day, 2:week
      graph_data_show: 1, // 1: mood-depressed, 2: mood-manic, 3: sleep, 4: medication, 5: exercise, 6: anxiety
      tracker_day: [],
      tracker_week: [],
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  componentWillReceiveProps(nextProps) {
    this.getDataList()
  }

  getDataList = () => {
    var get_tracker_day = []
    var get_tracker_week = []

    // tracker day //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient_assessment/get_tracker_by_type',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.pid,
        type: 1 }
    })
    .then((result) => {
      if(result['data'].length > 0) {
        get_tracker_day = result['data']
        this.setState({
          tracker_day: get_tracker_day,
        })
      }
    })
    // tracker week //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient_assessment/get_tracker_by_type',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.pid,
        type: 2
      }
    })
    .then((result) => {
        if(result['data'].length > 0) {
          get_tracker_week = result['data']
          this.setState({
            tracker_week: get_tracker_week,
          })
        }
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  changeConfigStatus = () => {
    if(this.state.graph_config_status == 1) {
      this.setState({ graph_config_status: 2 })
    } else {
      this.setState({ graph_config_status: 1 })
    }
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  genArr1(start, num) {
    var a = []
    for(var i=start; i<=num; i++) { a[i] = i }
    return a
  }

  genArr2() {
    var a = []
    var arr = []
    var name = ''

    if(this.state.graph_type == 1) {
      arr = this.state.tracker_day
    } else if(this.state.graph_type == 2) {
      arr = this.state.tracker_week
    }

    if(this.state.graph_data_show == 1) {
      name = 'mood_depressed'
    } else if(this.state.graph_data_show == 2) {
      name = 'mood_manic'
    } else if(this.state.graph_data_show == 3) {
      name = 'sleep'
    } else if(this.state.graph_data_show == 4) {
      name = 'medication'
    } else if(this.state.graph_data_show == 5) {
      name = 'exercise'
    } else if(this.state.graph_data_show == 6) {
      name = 'anxiety'
    }

    const st_date = 30
    var gen_date = ''
    var gen_val = {}
    var gen_mood_depressed = 0
    var result = {}

    //for(var i = 200; i >= 0; i--) {
    arr.map(each => {
      result = each
      if(typeof result !== 'undefined' && result[name] > -1) {
        if(name == 'mood_depressed') {
          gen_mood_depressed = 6 - result[name]
        } else {
          gen_mood_depressed = result[name]
        }
        gen_val = {
          x: result.date_time.slice(5, 7) + '/' + result.date_time.slice(8, 10),
          //gen_date.toISOString().slice(5, 7) + '/' + gen_date.toISOString().slice(8, 10),
          y: gen_mood_depressed,
        }
        a.push(gen_val)
      }
    })
    a.reverse()
    /*gen_date = new Date()
      gen_date.setDate(gen_date.getDate()-i)

      var result = arr.find(obj => {
        return obj.date_time.substr(5, 5) == gen_date.toISOString().slice(5, 10)
      })

      if(typeof result !== 'undefined' && result[name] > -1) {
        if(name == 'mood_depressed') {
          gen_mood_depressed = 6 - result[name]
        } else {
          gen_mood_depressed = result[name]
        }
        gen_val = {
          x: gen_date.toISOString().slice(5, 7) + '/' + gen_date.toISOString().slice(8, 10),
          y: gen_mood_depressed,
        }
        a.push(gen_val)
      }*/
    return a
  }

  genArr3(start, type, time) {
    var a = []
    var type = this.state.graph_type
    var show = this.state.graph_data_show
    //console.log(start, type, time, show)
    if(show == 1) {
      a = ch_daily_weekly_mood_dep3
    } else if(show == 2) {
      a = ch_daily_weekly_mood_manic2
    } else if(show == 3) {
      if(type == 1) {
        a = ch_daily_tracker_sleep
      } else {
        a = ch_weekly_tracker_sleep
      }
    } else if(show == 4) {
      if(type == 1) {
        a = ch_daily_tracker_medication
      } else {
        a = ch_weekly_tracker_medication
      }
    } else if(show == 5) {
      if(type == 1) {
        a = ch_daily_tracker_exercise
      } else {
        a = ch_weekly_tracker_exercise
      }
    } else if(show == 6) {
      a = ch_daily_weekly_anxiety2
    }
    return a
  }

  getXaxis() {
    var length = 0
    if(this.state.graph_type == 1) {
      length = this.state.tracker_day.length
    } else if(this.state.graph_type == 2) {
      length = this.state.tracker_week.length
    }
    return length
  }

  render() {
    return (
      <div className="small-12 columns box_space_top1 pt_graph">
        <div className="row">
          <div className="small-2 columns head2 float_left1 no_padding_right no_padding_left">
            Graph
          </div>
          { this.state.graph_config_status == 1 &&
          <div className="small-10 columns gh_filter">
            <div className="small-5 medium-2 columns float_right1 align_left1 no_padding_right graph_type">
              <FormControl variant="outlined">
                <Select value={this.state.graph_type} variant="outlined" name="graph_type"
                  label="" onChange={(e) => this.handleChange(e, 'graph_type')}>
                  {db_type.map(each =>
                    <MenuItem key={'dbt'+each.value} value={each.value}>{each.label}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="small-7 medium-3 columns float_right1 align_left1 no_padding_right graph_data_show">
            <FormControl variant="outlined">
              <Select value={this.state.graph_data_show} variant="outlined" name="graph_data_show"
                label="" onChange={(e) => this.handleChange(e, 'graph_data_show')}>
                {db_data_show.map(each =>
                  <MenuItem key={'dts'+each.value} value={each.value}>{each.label}</MenuItem>
                )}
              </Select>
            </FormControl>
            </div>
          </div>}
        </div>
        <div className="row">
          <div className="small-12 columns">
            <VictoryChart theme={VictoryTheme.material}
              padding={{ top: 5, bottom: 15, left: 30, right: 5 }}
              domainPadding={0} height={90} >
              <VictoryAxis crossAxis
                tickValues={this.genArr1(0, this.getXaxis()+1)}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 0},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 5, angle: 50},
                }}
              />
            <VictoryAxis dependentAxis crossAxis
                tickValues={this.genArr3(0, this.findLabelByValue(db_scale_day, this.state.graph_type, this.state.graph_data_show))}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 0},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 5}
                }}
              />
              <VictoryGroup data={this.genArr2()} color="#ffac29" >
            <VictoryLine
              domain={{x: [0, this.getXaxis()+1], y: [0, 6]}}
              style={{
                data: { stroke: "#ffac29", strokeWidth: 0.5 },
                parent: { border: "1px solid #cccccc"}
              }}
              data={this.genArr2()}
            />
            <VictoryScatter size={1.15} symbol="dot"/>
            </VictoryGroup>
            </VictoryChart>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Graph)
