import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/fittbd/Kerry.jpg'

class Intro8 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction9')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction7')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
          <div className="title_31 margin-bottom1">
          How it works:
          </div>
          <div className="row bottom_space3">
          <div className="small-12 medium-6 columns">
            <div className="img_center">
              <img src={Image} alt="group" className="img1" />
            </div>
          </div>
            <div className="small-12 medium-6 columns content11 margin-top1">
              <p className="title_32">MEET KERRY</p>
              <p>Let’s look at an imaginary patient to see how this might work.</p>
              <p>Kerry is 27 years old and was diagnosed with bipolar I last year after having her first manic episode.</p>
              <p>After being hospitalized, Kerry was unable to return to work and has been unemployed since. She has a bachelor's degree in marketing and wants to get back into the field.</p>
              <p>Kerry wants to transition back into her normal work  routine while managing her new diagnosis.</p>
            </div>
          </div>
          <div className="row">
            <div className="small-4 columns back_button">
              <Button variant="contained" color="primary" disableElevation
               onClick={this.handleBack} type="button" >
                Previous
              </Button>
            </div>
            <div className="small-4 columns next_button">
              <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro8)
