import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/fittbd/group_therapy2.png'
import Image2 from '../../../images/fittbd/kerry2.png'

class Intro10 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction11')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction9')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_video2"></div>
            <div className="row">
              <div className="small-12 medium-6 columns content2">
                <img src={Image} alt="pyramid" className="w_100percent margin-top2"/>
              </div>
              <div className="small-12 medium-6 columns gray_bg4">
                <div className="margin-top1"><img src={Image2} alt="pyramid" className="img3 margin-top1" /></div>
                <div className="content_under_picture2">
                  <p>Kerry also begins doing her online trackers and questionnaires.</p>
                  <p>After three group sessions, Kerry's group leader expresses concern about Kerry, whose assessments indicate that she has fallen into a severe depression.</p>
                  <p>When the team meets to review Kerry's plan, they recommend adding a few sessions of individual skills trainings along with group. Kerry agrees.</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="small-4 columns back_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro10)
