import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/fittbd/group_therapy1.png'
import Image2 from '../../../images/fittbd/Kerry.jpg'

class Intro9 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction10')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction8')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_video2"></div>
            <div className="row">
              <div className="small-12 medium-8 columns content2">
                <img src={Image} alt="pyramid" className="img3"/>
                <p>After enrolling in FITT-BD, Kerry meets with one of the team clinicians to come up with a treatment plan.</p>
                <p>The clinician who meets with Kerry notes that she is still struggling to come to terms with her bipolar I diagnosis.</p>
                <p>In consultation with the  team, Kerry joins a group program that helps people deal with internalized social stigma about bipolar disorder.</p>
              </div>
              <div className="small-12 medium-4 columns blue_bg1">
                <div className="margin-top1"><img src={Image2} alt="" className="w_80percentc" /></div>
                <div className="content_under_picture3">
                  <span className="title_33">Diagnosis:</span> Bipolar I
                  <br /><span className="title_33">Age:</span> 27
                  <br /><span className="title_33">Age when diagnosed:</span> 26
                  <br /><span className="title_33">Hospitalizations:</span> 1
                  <br /><span className="title_33">Education:</span> College
                  <br /><span className="title_33">Work:</span> Unemployed
                  <br /><span className="title_33">Goals:</span> Get a job; Better understand and manage my symptoms; Create a daily routine
                </div>
              </div>
            </div>

            <div className="row">
              <div className="small-4 columns back_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro9)
