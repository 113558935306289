import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactAudioPlayer from 'react-audio-player'
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/fittbd/book1.png'

class Intro14 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction15')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction13')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="row">
              <div className="small-12 columns bottom_space1 align_center">
                <img src={Image} alt="team" className="img" />
              </div>
              <div className="small-12 columns content2">
                <p>As you can see, the FITT-BD program is quite flexible.</p>
                <p>Depending on how you’re doing: <br />
                  <ul>
                    <li>You may start one or more treatments.</li>
                    <li>You may check in with your patient navigator regularly or only occasionally.</li>
                    <li>You may work on your own to develop skills you need or you may meet with a clinician for short-term skills training sessions to work on specific issues.</li>
                  </ul>
                </p>
                <p>Every patient’s path will be different, depending on what they need and how they progress in the program.</p>
              </div>
            </div>
            <div className="row">
              <div className="small-4 columns back_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro14)
