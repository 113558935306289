import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

class PatientCleanData extends Component {
  constructor() {
      super();
      this.state = {
          type: 0,
          uid: 0,
          pid: 0,
          status: 0,
          message: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var url = ''
    var msg = ''
    if(this.props.match.params.type == 1) {
      url = 'admin/patient/clean_patient_contract'
      msg = 'Delete patient contract of uid '+this.props.match.params.pid+' completed!'
    }
    axios({
      method: 'post',
      url: connectNode + url,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'] == this.props.match.params.pid) {
        this.setState({
          message: msg,
          status: 1,
        })
      } else {
        this.setState({
          message: 'Something wrong, try again!',
          status: 2,
        })
      }
    })
  }

  render() {
    return (
      <div className="admin_patient_detail box_border4">
      <div className="row">
        <div className="small-12 columns ">
          <div className="row">
            <div className="small-12 columns">
              {(this.state.status == 1 || this.state.status == 2) && this.state.message}
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientCleanData)
