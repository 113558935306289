import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { cgi_type, cgi_mood, mood_state_long, mood_state_long_type,
  severity_illness, improvement } from '../variables/assessments/cgi_bd.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CheckIcon from '@material-ui/icons/Check'

import PatientHead3 from "../patient/patient_head3"

class CgiBDview extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          date: new Date().toISOString().substring(0, 10),
          rater: 0,
          rater_name: '',
          type: 0,
          mood_initial: 0,
          mood_state_long: 0,
          mood_state_long_type: 0,
          severity_a: 0,
          severity_b: 0,
          severity_c: 0,
          severity_mixmood: 0,
          improvement_a: 0,
          improvement_b: 0,
          improvement_c: 0,
          start_datetime: Math.floor(new Date().getTime()/1000),

      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    // get Rater name //
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_cgi_bd_each',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        id: this.props.match.params.id,
      }
    })
    .then((result) => {
      this.setState({
        rater_name: result['data'].c_name,
        date: result['data'].date_visit,
        type: result['data'].type,
        mood_initial: result['data'].mood_initial,
        mood_long: result['data'].mood_long,
        mood_long_type: result['data'].mood_long_type,
        severity_a: result['data'].s_a_name,
        severity_b: result['data'].s_b_name,
        severity_c: result['data'].s_c_name,
        severity_mixmood: result['data'].s_mix_name,
        improvement_a: result['data'].i_a_name,
        improvement_b: result['data'].i_b_name,
        improvement_c: result['data'].i_c_name,
        anxiety_disorder: result['data'].anxiety_name,
        behavioral_substance: result['data'].behavioral_substance_name,
      })
    })
  }

  render() {
    return (
      <div className="assm_mini box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Clinical Global Impressions – Bipolar Disorder (CGI-BD)
          </div>
        </div>
        <PatientHead3 />
        { this.props.authReducer.role == 3 &&
          <form onSubmit={this.handleSubmit}>
          <div className="row top_space2">
            <div className="small-12 columns box1">
              <div className="row">
                <div className="small-12 columns top_space2">
                  <span className="bold">Rater:</span> {this.state.rater_name}
                </div>
                <div className="small-12 columns">
                  <span className="bold">Date:</span> {this.state.date}
                </div>
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 columns">
                {cgi_type.map(each =>
                  each.value==this.state.type &&
                  <div className="each_choice1" key={'type'+each.label}>
                      <CheckIcon /> {each.label}
                  </div>
                )}
                </div>

                {this.state.mood_initial > 0 &&
                <div>
                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>
                  <div className="small-12 columns">
                    A. What was the patient’s mood at the initial assessment?
                  </div>
                  {cgi_mood.map(each =>
                    each.value==this.state.mood_initial &&
                    <div className="small-12 columns" key={'mi'+each.label}>
                      <CheckIcon /> {each.label}
                    </div>
                  )}
                  <div className="small-12 columns">
                    B. How long had the patient been in this mood state?
                  </div>
                  <div className="small-12 columns"><CheckIcon />
                  {mood_state_long.map( each =>
                    each.value == this.state.mood_long && each.label)} &nbsp;
                  {mood_state_long_type.map(each =>
                    each.value == this.state.mood_long_type && each.label)}
                  </div>
                </div>}

                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 columns">
                  <div className="bold">1. Severity of Illness: Bipolar Disorder</div>
                  <div>Considering your total clinical experience with bipolar patients, how severely ill has the patient been during the past week?</div>
                </div>
                <div className="small-12 columns top_space2">
                  <div className="row">
                    <div className="small-12 medium-3 columns">a. Mania</div>
                    <div className="small-12 medium-6 columns float_left">
                    {this.state.severity_a}
                    </div>
                  </div>
                </div>
                <div className="small-12 columns top_space2">
                  <div className="row">
                    <div className="small-12 medium-3 columns">b. Depression</div>
                    <div className="small-12 medium-6 columns float_left">
                    {this.state.severity_b}
                    </div>
                  </div>
                </div>
                <div className="small-12 columns top_space2">
                  <div className="row">
                    <div className="small-12 medium-3 columns">c. Mixed mood state</div>
                    <div className="small-12 medium-6 columns float_left">
                    {this.state.severity_mixmood}
                    </div>
                  </div>
                </div>
                <div className="small-12 columns top_space2">
                  <div className="row">
                    <div className="small-12 medium-3 columns">d. Overall Bipolar Illness</div>
                    <div className="small-12 medium-6 columns float_left">
                    {this.state.severity_c}
                    </div>
                  </div>
                </div>

                {!this.state.anxiety_disorder &&
                <div>
                  <div className="small-12 columns top_space2">
                    <div className="top_space2">1 = Normal—not at all ill, symptoms of disorder not present past seven days</div>
                    <div>2 = Borderline mentally ill—subtle or suspected pathology</div>
                    <div>3 = Mildly ill—clearly established symptoms with minimal, if any, distress or difficulty in social and occupational function</div>
                    <div>4 = Moderately ill—overt symptoms causing noticeable, but modest, functional impairment or distress; symptom level may warrant medication</div>
                    <div>5 = Markedly ill—intrusive symptoms that distinctly impair social/occupational function or cause intrusive levels of distress</div>
                    <div>6 = Severely ill—disruptive pathology, behavior and function are frequently influenced by symptoms, may require assistance from others</div>
                    <div>7 = Among the most extremely ill patients—pathology drastically interferes in many life functions; may be hospitalized</div>
                  </div>

                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>
                  <div className="small-12 columns">
                    <div className="bold">II. Improvement</div>
                    <div>Compared to the phase immediately preceding admission assessment, how much has the patient changed in the past week?</div>
                  </div>
                  <div className="small-12 columns top_space2">
                    <div className="row">
                      <div className="small-12 medium-3 columns">a. Mania</div>
                      <div className="small-12 medium-6 columns float_left">
                      {this.state.improvement_a}
                      </div>
                    </div>
                  </div>
                  <div className="small-12 columns top_space2">
                    <div className="row">
                      <div className="small-12 medium-3 columns">b. Depression</div>
                      <div className="small-12 medium-6 columns float_left">
                      {this.state.improvement_b}
                      </div>
                    </div>
                  </div>
                  <div className="small-12 columns top_space2">
                    <div className="row">
                      <div className="small-12 medium-3 columns">c. Overall Bipolar Illness</div>
                      <div className="small-12 medium-6 columns float_left">
                      {this.state.improvement_c}
                      </div>
                    </div>
                  </div>
                  <div className="small-12 columns top_space2">
                    <div className="top_space2">1 = Very much improved—nearly all better; good level of functioning; minimal symptoms; represents a very substantial change</div>
                    <div>2 = Much improved—notably better with significant reduction of symptoms; increase in the level of functioning but some symptoms remain</div>
                    <div>3 = Minimally improved—slightly better with little or no clinically meaningful reduction of symptoms. Represents very little change in basic clinical status, level of care, or functional capacity</div>
                    <div>4 = No change—symptoms remain essentially unchanged</div>
                    <div>5 = Minimally worse—slightly worse but may not be clinically meaningful; may represent very little change in basic clinical status or functional capacity</div>
                    <div>6 = Much worse—clinically significant increase in symptoms and diminished functioning</div>
                    <div>7 = Very much worse—severe exacerbation of symptoms and loss of functioning</div>
                  </div>
                </div>}

                {this.state.anxiety_disorder &&
                <div>
                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>
                  <div className="small-12 columns">
                    <div className="bold">2. Severity of Illness: Anxiety Disorder</div>
                    <div>Considering your total clinical experience with anxiety disorder patients, how severely ill has the patient been during the past week?</div>
                  </div>
                  <div className="small-12 columns top_space2">
                    <div className="row">
                      <div className="small-6 columns float_left">
                      {this.state.anxiety_disorder}
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>
                  <div className="small-12 columns">
                    <div className="bold">3. Severity of Illness: Behavioral and Substance Use Disorder</div>
                    <div>Considering your total clinical experience with behavioral and substance use disorder patients, how severely ill has the patient been during the past week?</div>
                  </div>
                  <div className="small-12 columns top_space2">
                    <div className="row">
                      <div className="small-6 columns float_left">
                      {this.state.behavioral_substance}
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>
                  <div className="small-12 columns">
                    <div className="bold">Severity scale for all items: </div>
                    <div className="top_space2">1 = Normal—not at all ill, symptoms of disorder not present past seven days</div>
                    <div>2 = Borderline mentally ill—subtle or suspected pathology</div>
                    <div>3 = Mildly ill—clearly established symptoms with minimal, if any, distress or difficulty in social and occupational function</div>
                    <div>4 = Moderately ill—overt symptoms causing noticeable, but modest, functional impairment or distress; symptom level may warrant medication</div>
                    <div>5 = Markedly ill—intrusive symptoms that distinctly impair social/occupational function or cause intrusive levels of distress</div>
                    <div>6 = Severely ill—disruptive pathology, behavior and function are frequently influenced by symptoms, may require assistance from others</div>
                    <div>7 = Among the most extremely ill patients—pathology drastically interferes in many life functions; may be hospitalized</div>
                  </div>
                </div>}

              </div>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns align_right">
              <Link to={"/admin/patient_chart/"+this.props.match.params.pid+"/3/3"}
              className="go_back">Go Back</Link>
            </div>
          </div>
        </form>
        }

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CgiBDview)
