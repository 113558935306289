import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/list1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import CoursesAssign from "./courses_assign"
import CourseProgress from "./course_progress"

class TabCourses extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          tab_status: '3',
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          date: '',
          time: '',
          duration: 0,
          patient_status: 0,
      }
  }

  componentDidMount() {
    if(this.props.match.params.secondtab > 0) {
      this.setState({
        tab_status: this.props.match.params.secondtab,
      })
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_status: newValue,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/add_new_patient',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: this.state
    })
    .then((result) => {
        //console.log('Callback: '+result['data'])
        if(result['data'] > 0) {
          //this.props.onLoadPages(response)
          this.props.onLoadLocation('patient_list', 'Adding new patient completed!')
          this.props.history.push('/admin/patient_list')
          /*this.setState({
            message: 'Adding new patient completed!'
          })*/
        } else if(result['data'] === -1) {
          this.setState({
            message: 'This email already exist.'
          })
        } else if(result['data'] === -2) {
          this.setState({
            message: 'Adding new patient completed but system couldn\'t send email reminder!'
          })
        } else {
          this.setState({
            message: 'Something went wrong. Please try again.'
          })
        }
      }
    )
  }

  render() {

    return (
      <div className="admin_make_appointment">
        <div className="row">
          <div className="small-12 columns tab_data1 less_space1">
            <TabContext value={this.state.tab_status}>
              <AppBar position="static">
                <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
                  <Tab label="Courses/Assign" value="1" />
                  <Tab label="Courses/Progress" value="2" />
                  <Tab label="Worksheets/Assign" value="3" />
                  {/* */}
                </TabList>
              </AppBar>
              <TabPanel value="1" index={1} >
              {this.state.tab_status == 1 && <CoursesAssign />}
              </TabPanel>
              <TabPanel value="2" index={2} >
              {this.state.tab_status == 2 && <CourseProgress />}
              </TabPanel>
              <TabPanel value="3" index={3} >
              {this.state.tab_status == 2 && <CourseProgress />}
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TabCourses)
