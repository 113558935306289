export const clinicians = [
  {
    label: 'Louisa Sylvia',
    value: 2,
    sub_role: 1,
  }, {
    label: 'Douglas Katz',
    value: 3,
    sub_role: 1,
  }, {
    label: 'Masoud Kamali',
    value: 58,
    sub_role: 1,
  }, {
    label: 'Amy Peters',
    value: 59,
    sub_role: 1,
  }, {
    label: 'Chelsea Boccagno',
    value: 57,
    sub_role: 2,
  }, {
    label: 'Other',
    value: 1,
    sub_role: 1,
  }, {
    label: 'Resource Specialist',
    value: 1,
    sub_role: 3,
  }// Peer councelor //
  /*, {
    label: 'Chelsea Boccagno7',
    value: 577,
    sub_role: 2,
  }, {
    label: 'Alec Shannon',
    value: 60,
    sub_role: 1,
  }, */
]

export const appt_type = [
  {
    label: 'Consult',
    value: 1,
  }, {
    label: 'Group',
    value: 2,
  }, {
    label: 'New Patient',
    value: 3,
  }, {
    label: 'Psychopharmacology',
    value: 4,
  }, {
    label: 'Semi-Urgent Follow-Up',
    value: 5,
  }, {
    label: 'Semi-Urgent New MGH',
    value: 6,
  }, {
    label: 'Therapy',
    value: 7,
  }, {
    label: 'Transfer',
    value: 8,
  }, {
    label: 'Urgent Follow-Up',
    value: 9,
  }, {
    label: 'Urgent New MGH',
    value: 10,
  }, {
    label: 'Virtual',
    value: 11,
  }, {
    label: 'Telephone',
    value: 12,
  }, {
    label: 'CBT Outcomes',
    value: 13,
  }, {
    label: 'Peer Support',
    value: 14,
  }, {
    label: 'Patient Navigator',
    value: 15,
  },
]

export const appt_sub_type = [
  {
    label: 'CBT Outcomes',
    value: 13,
  }, {
    label: 'Consult, Neuropsych: Assessments/Feedback',
    value: 6,
  }, {
    label: 'Consult, Other',
    value: 2,
  }, {
    label: 'Consult, Psychopharmacology',
    value: 4,
  }, {
    label: 'Discharge',
    value: 11,
  }, /*{
    label: 'Intake Appointment',
    value: 1,
  }, */{
    label: 'Intake Appointment',
    value: 12,
  }, {
    label: 'Patient Navigator',
    value: 15,
  }, {
    label: 'Peer Support',
    value: 14,
  }, {
    label: 'Psychoeducation',
    value: 9,
  }, {
    label: 'Psychopharmacology',
    value: 16,
  }, {
    label: 'Reassessment',
    value: 10,
  }, {
    label: 'Therapy, Group',
    value: 3,
  }, {
    label: 'Therapy, Individual',
    value: 5,
  }, /*{
    label: 'Neuropsychological Assessment',
    value: 6,
  }, {
    label: 'Neuropsychological Feedback',
    value: 7,
  }, /*{
    label: 'Staff Check-In',
    value: 8,
  },*/
]

export const appt_sub_type2 = [
  {
    label: 'CBT Outcomes',
    value: 13,
  }, {
    label: 'Consult, Neuropsych: Assessments/Feedback',
    value: 6,
  }, {
    label: 'Consult, Other',
    value: 2,
  }, {
    label: 'Consult, Psychopharmacology',
    value: 4,
  }, {
    label: 'Discharge',
    value: 11,
  }, {
    label: 'Intake Appointment',
    value: 1,
  }, {
    label: 'Patient Navigator',
    value: 15,
  }, {
    label: 'Peer Support',
    value: 14,
  }, {
    label: 'Psychoeducation',
    value: 9,
  }, {
    label: 'Psychopharmacology',
    value: 16,
  }, {
    label: 'Reassessment',
    value: 10,
  }, {
    label: 'Therapy, Group',
    value: 3,
  }, {
    label: 'Therapy, Individual',
    value: 5,
  },
]

export const appt_type_show = [ '',
  'Consult', // 1
  'Group', // 2
  'New Patient', // 3
  'Psychopharmacology', // 4
  'Semi-Urgent Follow-Up', // 5
  'Semi-Urgent New MGH', // 6
  'Therapy', // 7
  'Transfer', // 8
  'Urgent Follow-Up', // 9
  'Urgent New MGH', // 10
  'Virtual', // 11
  'Telephone', // 12
  'CBT Outcomes', // 13
  'Peer Support', // 14
  'Patient Navigator', // 15
]

export const appt_sub_type_show = [ '',
  'Consult', // 1
  'Group', // 2
  'Psychopharmacology', // 3
  'Therapy', // 4
  'Neuropsychological Assessment', // 5
  'Neuropsychological Feedback', // 6
  'Staff Check-In', // 7
  'Psychoeducation', // 8
  'Reassessment', // 9
  'Discharge', // 10
  '',
  'CBT Outcomes',
  'Peer Support',
  'Patient Navigator',
]

export const appt_visit = [
  {
    label: 'Virtual',
    value: 1,
  }, {
    label: 'In person',
    value: 2,
  }, {
    label: 'Phone',
    value: 3,
  },
]

export const appt_status = [ '',
  'Upcoming (Next 7 Days)', // 1
  'Upcoming (Rescheduled)', // 2
  'Cancelled', // 3
  'Completed', // 4
  'No-show', // 4
]

export const frequency = [
  {
    label: 'One time appointment',
    value: 1,
  }, {
    label: 'Recurrent',
    value: 2,
  },
]

export const frequency_time = [
  {
    label: '1 week',
    value: 1,
  }, {
    label: '2 weeks',
    value: 2,
  }, {
    label: '4 weeks',
    value: 3,
  }, {
    label: '12 weeks',
    value: 4,
  },
]

export const ans_yesno = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const ans_yesno_think = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }, {
    label: 'Think about it',
    value: 3,
  },
]

export const ans_yes_think = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'Think about it',
    value: 3,
  },
]

export const ans_onoff = [
  {
    label: 'Enable',
    value: 1,
  }, {
    label: 'Disable',
    value: 0,
  },
]

export const referral_source = [
  {
    label: 'DCBI clinician',
    value: 1,
  }, {
    label: 'External clinician ',
    value: 2,
  }, {
    label: 'Advocacy group',
    value: 3,
  }, {
    label: 'Triage call',
    value: 4,
  }, {
    label: 'Advertisement',
    value: 5,
  }, {
    label: 'Web search',
    value: 6,
  }, {
    label: 'Other',
    value: 7,
  },
]

export const screening = [
  {
    label: 'Telephone',
    value: 1,
  }, {
    label: 'Online',
    value: 2,
  }
]

export const how_you_hear_us = [
  {
    label: 'Advertisement',
    value: 1,
  }, {
    label: 'Web search',
    value: 2,
  }, {
    label: 'Other',
    value: 3,
  }
]

export const filter_appt_status = [
  {
    label: 'All Appointments',
    value: 0,
  }, {
    label: 'Upcoming (Next 7 Days) & Past',
    value: 6,
  }, {
    label: 'Past',
    value: 1,
  }, {
    label: 'Upcoming (Next 7 Days)',
    value: 2,
  }, {
    label: 'Future',
    value: 3,
  }, {
    label: 'Cancel',
    value: 4,
  }, {
    label: 'No-show',
    value: 5,
  },
]

export const pn_checkin_frequency = [
  {
    label: 'Weekly',
    value: 1,
  }, {
    label: 'Bi-weekly',
    value: 2,
  }, {
    label: 'Monthly',
    value: 3,
  }, {
    label: 'None',
    value: 4,
  },
]

export const waitlist_status = [
  {
    label: 'Active',
    value: 1,
  }, {
    label: 'Enrolled',
    value: 2,
  }, {
    label: 'Withdrawn',
    value: 3,
  },
]
