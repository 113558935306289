import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import '../../../styles/assets/css/pages/stepper1.css'
import '../../../styles/assets/css/pages/form7.css'
import { loadLocationWithMessage } from '../../../actions/location'

import { calculateTimepoint1 } from '../../functions/timepoint.js'
import StepperAssm from './stepper_assm'

//import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

class Overview extends Component {
  constructor() {
      super()
      this.state = {
          status: 1, // 1: current, 2: completed current, 3: past timepoint
          message: '',
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    var timepoint = this.props.match.params.timepoint
    if(timepoint == -1) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_baseline_overview',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { uid: this.props.authReducer.uid }
      })
      .then((result) => {
          if(result['data'].baseline_step !== 'overview') {
            if(result['data'].baseline_step == 'ineligible') {
              this.props.history.push('/member/assessment/sorry/1')
            } else if(result['data'].baseline_step == 'incomplete_intake') {
              this.props.history.push('/member/assessment/sorry/2')
            } else {
              this.props.history.push('/member/assessment/'+result['data'].baseline_step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            }
          }
        }
      )
    } else if(timepoint == -2 || timepoint <= -9) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_assm_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
         }
      })
      .then((result) => {
        //console.log(result['data'].step)
        if(result['data'].step === null ) {
          // Insert new row //
          axios({
            method: 'post',
            url: connectNode + 'patient/assessment/add_new_assm_process',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              uid: this.props.authReducer.uid,
              type: this.props.match.params.type,
              timepoint: this.props.match.params.timepoint,
             }
          })
          .then((result) => { })
        } else {
          this.props.history.push('/member/assessment/'+result['data'].step+'/m/'+timepoint)
        }
      })
    } else {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_assm_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
         }
      })
      .then((result) => {
        var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
        if(this.props.match.params.timepoint > calTimepoint.current_month) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(this.props.match.params.timepoint == calTimepoint.current_month) {
          // Current timepoint => Fill the form //
          if(result['data'].status !== null) {
            if(result['data'].status == 1 && result['data'].step !== 'overview') {
              this.props.history.push('/member/assessment/'+result['data'].step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            }
            if(result['data'].status == 1 && result['data'].overview_end !== null) {
              // Completed this page //
              this.setState({ status: 2 })
            }
          } else {
            // Insert new row //
            axios({
              method: 'post',
              url: connectNode + 'patient/assessment/add_new_assm_process',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
              },
              data: {
                uid: this.props.authReducer.uid,
                type: this.props.match.params.type,
                timepoint: this.props.match.params.timepoint,
               }
            })
            .then((result) => { })
          }
        } else {
          // Past timepoint //
          this.setState({ status: 3 })
        }
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    if(timepoint == -1) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/update_assessment_process1',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          end_step: 'overview_end',
          start_step: 'phq9_start',
          next_step: 'phq9',
          timepoint: this.props.match.params.timepoint,
        }
      })
      .then((result) => {
          if(result['data'] === 'phq9') {
            this.props.onLoadLocation('/member/assessment/phq9/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            this.props.history.push('/member/assessment/phq9/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
          }

        }
      )
    } else {
      if(this.state.status == 1) {
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/update_assessment_process2',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            end_step: 'overview_end',
            next_step: 'phq9',
            type: this.props.match.params.type,
            timepoint: this.props.match.params.timepoint,
          }
        })
        .then((result) => {
            if(result['data'] === 'phq9') {
              this.props.onLoadLocation('/member/assessment/phq9/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
              this.props.history.push('/member/assessment/phq9/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            }
          }
        )
      } else {
        this.props.history.push('/member/assessment/phq9/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
      }

    }
  }

  render() {

    return (
      <div className="welcome_overview box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
          {this.props.match.params.timepoint == -1 && 'New Patient Questionnaires' }
          {this.props.match.params.timepoint == -2 && 'Discharge Assessments' }
          {this.props.match.params.type == 'm' && this.props.match.params.timepoint > -1 && 'Monthly Questionnaires: Instructions' }
          {this.props.match.params.type == 'm' && this.props.match.params.timepoint <= -9 && 'Unscheduled Monthly Questionaires' }
          {this.props.match.params.type == 'tm' && this.props.match.params.timepoint <= -9 && 'Unscheduled Trimonthly Questionaires' }
          </div>
          <StepperAssm activeStep={0} assm={'Overview'} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg5">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          { this.props.match.params.timepoint == -1 &&
            <div className="small-12 columns box1">
                <p className="txt1">Welcome to the FITT-BD Clinic!</p>
                <p>The following questionnaires are to assess how you are doing right now. This will give us a baseline from which to evaluate your progress over the coming weeks. The information from these questionnaires will also help us to work with you to create a treatment plan that is based on your individual needs.</p>
                <p>Please complete these questionnaires within the next week. They should take you about 45 minutes to complete. If you finish several questionnaires and want to take a break before doing the rest, you may save your information and log out. However, you must complete each individual questionnaire in its entirety at one sitting. If you do only part of a questionnaire, your information will not be saved and you will have to start that questionnaire from the beginning the next time you log in.</p>
                <p>Thank you!</p>
            </div>
          }
          { this.props.match.params.timepoint == -2 &&
            <div className="small-12 columns box1">
                <p className="txt1">Thank you for joining the FITT-BD Clinic!</p>
                <p>The following questionnaires are to assess how you’re doing at the time of your discharge. Your answers will help us tailor your discharge recommendations. They will also give us valuable information about the effectiveness of FITT-BD that will allow us to improve the program for future patients. We appreciate your help.</p>
                <p>Please try to complete these questionnaires within one week. If you finish several questionnaires and want to take a break and come back, you may save your information and log out. However, you must complete each individual questionnaire in its entirety at one sitting. If you do only part of a questionnaire, your information will not be saved and you will have to start that questionnaire from the beginning the next time you log in.</p>
                <p>Thank you!</p>
            </div>
          }
          { this.props.match.params.type == 'm' && (this.props.match.params.timepoint > -1 || this.props.match.params.timepoint <= -9) &&
            <div className="small-12 columns box1">
                <p>The following questionnaires are to find out how you're doing at this stage in your treatment. Your answers will help you and your team evaluate the effectiveness of your treatment plan.</p>
                <p>You will have one week to complete these questionnaires. If you finish several questionnaires and want to take a break and come back, you may save your information and log out. However, you must complete each individual questionnaire in its entirety at one sitting. If you do only part of a questionnaire, your information will not be saved and you will have to start that questionnaire from the beginning the next time you log in.</p>
                <p>If you don't take medication, you do not have to fill out the Medication Tracking Form.</p>
                <p>Thank you!</p>
            </div>
          }
          <div className="small-12 columns align_right">
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Next
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Overview)
