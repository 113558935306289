import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { scid_yesno, scid_ynmb } from '../variables/assessments/scid.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import StepperSCID from './stepper_SCID'

class Scid4 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          completed_status: false,
          edit_status: false,
          message: '',
          appt_id: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          next_step: 'scid5',
          j1: 0,
          j1_med_condition: '',
          j1_depress: 0,
          j1_bipolar: 0,
          j1_anxiety: 0,
          j1_ocd: 0,
          j2: 0,
          j2_med_condition: '',
          j2_depress: 0,
          j2_bipolar: 0,
          j2_anxiety: 0,
          j2_ocd: 0,
      }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    if(this.props.match.params.task == 'edit') {
      this.getData('edit')
    } else {
      // Initial MINI form to database //
      axios({
        method: 'post',
        url: connectNode + 'admin/assessment/chk_scid_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: this.props.match.params.pid,
          timepoint: this.props.match.params.timepoint,
        }
      })
      .then((result) => {
        //console.log(result['data'])
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          //console.log('step: '+result['data'].mini_step)
          if(result['data'].scid_step === 'complete') {
            this.props.history.push('/admin/assessments/scid_result/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
          } else if(result['data'].scid_step !== 'scid4') {
            this.getData('new')
          } else {
            this.setState({
              appt_id: result['data'].id,
            })
          }
        }
      })
    }
  }

  getData = (edit_status) => {
    var get_edit_status = false
    if(edit_status == 'edit') {
      get_edit_status = true
    }
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_scid_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'].end_2_timestamp > 0) {
        this.setState({
          completed_status: true,
          edit_status: get_edit_status,
          j1: result['data'].j1,
          j1_med_condition: result['data'].j1_med_condition,
          j1_depress: result['data'].j1_depress,
          j1_bipolar: result['data'].j1_bipolar,
          j1_anxiety: result['data'].j1_anxiety,
          j1_ocd: result['data'].j1_ocd,
          j2: result['data'].j2,
          j2_med_condition: result['data'].j2_med_condition,
          j2_depress: result['data'].j2_depress,
          j2_bipolar: result['data'].j2_bipolar,
          j2_anxiety: result['data'].j2_anxiety,
          j2_ocd: result['data'].j2_ocd,
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleNext = (page) => {
    window.location.href = "/admin/assessments/"+page+"/"+this.props.match.params.pid+"/-1"
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
    this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)

    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/update_scid4',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        timepoint: timepoint,
      }
    })
    .then((result) => {
      if(result['data'] === this.state.next_step) {
        this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
        this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
      }
    })
  }

  handleEdit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/edit_scid4',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        task: 'edit',
      }
    })
    .then((result) => {
      if(result['data'] == pid) {
        this.props.onLoadLocation('/admin/assessments/scid_result/'+pid+'/-1', 'Edit successfully!')
        this.props.history.push('/admin/assessments/scid_result/'+pid+'/-1')
      }
    })
  }

  render() {
    return (
      <div className="assm_mini box_border4">
        { //this.state.appt_id > 0 &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            QuickSCID5: Intake Appointment
          </div>
          <StepperSCID activeStep={3} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />
        </div>}
        {/*this.props.match.params.task == 'edit' &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            MINI Summary Sheet: Intake Appointment
          </div>
        </div>*/}
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        { //(this.state.appt_id > 0 || this.props.match.params.task == 'edit') &&
          <form onSubmit={this.handleSubmit}>
          <div className="row">
          <div className="small-12 columns box1">
            <div className="row show-for-medium bold">
              <div className="medium-9 columns">&nbsp;</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="medium-1 columns headerResize1" key={'sch'+each.label}>
                  {each.label}
                </div>
              )}
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
            </div>
            <div className="row" >
              <div className="small-12 medium-9 columns">
                J1. Probable Mental Disorder due to Another Medical Condition
              </div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j1" value={each.value}
                  checked={this.state['j1'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span className="show-for-small-only">{each.label}</span>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 columns">
                <span className="float_left1">Medical condition: </span>
                <span className="float_left1"><TextField label="" name="j1_med_condition"
                onChange={(e) => this.handleChange(e)} variant="outlined" className="tf1"
                InputProps={{ maxLength: 100 }}  /></span>
              </div>
              <div className="small-12 columns">Indicate type of mental disorder:</div>
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-8 columns">Depressive Disorder</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j1_depress" value={each.value}
                  checked={this.state['j1_depress'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-8 columns">Bipolar and Related Disorder</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j1_bipolar" value={each.value}
                  checked={this.state['j1_bipolar'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-8 columns">Anxiety Disorder</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j1_anxiety" value={each.value}
                  checked={this.state['j1_anxiety'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-8 columns">Obsessive-Compulsive and Related Disorder</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j1_ocd" value={each.value}
                  checked={this.state['j1_ocd'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                </div>)}
              <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
            </div>
            <div className="row" >
              <div className="small-12 medium-9 columns">
                J2. Probable Substance/Medication-Induced Mental Disorder
              </div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j2" value={each.value}
                  checked={this.state['j2'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span className="show-for-small-only">{each.label}</span>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 columns">
                <span className="float_left1">Medical condition: </span>
                <span className="float_left1"><TextField label="" name="j2_med_condition"
                onChange={(e) => this.handleChange(e)} variant="outlined" className="tf1"
                InputProps={{ maxLength: 100 }}  /></span>
              </div>
              <div className="small-12 columns">Indicate type of mental disorder:</div>
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-8 columns">Depressive Disorder</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j2_depress" value={each.value}
                  checked={this.state['j2_depress'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-8 columns">Bipolar and Related Disorder</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j2_bipolar" value={each.value}
                  checked={this.state['j2_bipolar'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-8 columns">Anxiety Disorder</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j2_anxiety" value={each.value}
                  checked={this.state['j2_anxiety'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-8 columns">Obsessive-Compulsive and Related Disorder</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="j2_ocd" value={each.value}
                  checked={this.state['j2_ocd'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                </div>)}
              <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
            </div>
          </div>

          <div className="small-12 columns align_right">
            {!this.state.completed_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Submit
            </Button>}
            {this.state.completed_status && !this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={(e) => this.handleNext('scid5')} className="submit_1">
                Next
            </Button>}
            {this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleEdit} className="submit_1">
                Submit
            </Button>}
          </div>
        </div>
        </form>
        }

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Scid4)
