import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/fittbd/team3.jpg'

class Intro5 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction6')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction4')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
          <div className="title_31 margin-bottom1">
            Team-based
          </div>
          <div className="row bottom_space3">
            <div className="small-12 medium-6 columns content11 margin-top1">
              <p>Central to FITT-BD is collaboration. As a patient, you’ll work with a team of people with different areas of expertise and different approaches to bipolar care.</p>
              <p>The FITT-BD team includes:<br />
                - Psychologists<br />
                - Psychopharmacologists<br />
                - Graduate fellows or practicum students<br />
                - Patient navigators<br />
                - A neuropsychologist<br />
                - A peer specialist</p>
            </div>
            <div className="small-12 medium-6 columns">
              <div className="img_center">
                <img src={Image} alt="group" className="img1" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="small-4 columns back_button">
              <Button variant="contained" color="primary" disableElevation
               onClick={this.handleBack} type="button" >
                Previous
              </Button>
            </div>
            <div className="small-4 columns next_button">
              <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro5)
