import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/fittbd/goal.png'

class Intro6 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction7')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction5')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
          <div className="title_31 margin-bottom1">
          Goal-oriented
          </div>
          <div className="row bottom_space3">
            <div className="small-12 medium-6 columns content11 margin-top1">
              <p>When you start the  FITT-BD program, you’ll choose clear, concrete goals. These goals could be anything that is important to you. For example:</p>
              <p>- Go back to school<br />
              - Do more things I enjoy<br />
              - Feel better about where I am in my life</p>
              <p>You’ll then work with your team to come up with a treatment plan to help you reach your goals. You'll also regularly meet with the team to review your goals and adjust your treatment plan.</p>
            </div>
            <div className="small-12 medium-6 columns">
              <div className="img_center">
                <img src={Image} alt="group" className="img1" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="small-4 columns back_button">
              <Button variant="contained" color="primary" disableElevation
               onClick={this.handleBack} type="button" >
                Previous
              </Button>
            </div>
            <div className="small-4 columns next_button">
              <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro6)
