import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactAudioPlayer from 'react-audio-player'
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/fittbd/brain.png'

class Intro2 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction3')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction1')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_31 margin-bottom1">
              WHAT IS FITT-BD?
            </div>
            <div className="row bottom_space3">
              <div className="small-12 medium-8 columns content11 margin-top1">
                <p>
                FITT-BD is an outpatient clinic at the Dauten Family Center for Bipolar Treatment Innovation at Massachusetts General Hospital.
It's designed to help people with bipolar disorder gain the skills and understanding they need to independently manage their condition.
                </p>
                <p>
                <b>To join FITT-BD, you must:</b><br />
                - Be 18 and older,<br />
                - Live in Massachusetts, and<br />
                - Have a psychiatrist, PCP, or someone who prescribes medication.
                </p>
              </div>
              <div className="small-12 medium-4 columns">
                <div className="img_center">
                  <img src={Image} alt="team" className="img1" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="small-4 columns back_button">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro2)
